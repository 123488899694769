import { SitecoreRouteContainer } from '@containers';
import { getSSP } from '@dxp-next-server';
import type { NextCatchAllRoute } from '@dxp-next-server';
import Layout from '@layouts/main';

import { countryCode, businessUnit, label, name, transformFunctions } from '../config';
import components from '../sitecoreComponents';

const CatchAllRoute: NextCatchAllRoute = ({
  dataLayerEvents,
  i18n,
  idToken,
  layoutData,
  nbaData,
  path,
  platformHint,
  user,
}) => (
  <SitecoreRouteContainer
    businessUnit={businessUnit}
    components={components}
    dataLayerEvents={dataLayerEvents}
    i18n={i18n}
    idToken={idToken}
    label={label}
    layoutData={layoutData}
    name={name}
    path={path}
    nbaData={nbaData}
    platformHint={platformHint}
    user={user}>
    <Layout />
  </SitecoreRouteContainer>
);

export default CatchAllRoute;

export const getServerSideProps = getSSP({ businessUnit, countryCode, label, transformFunctions });
